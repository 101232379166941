import React, { Component } from "react"
import { Link } from "gatsby"
import { Grid } from "@material-ui/core"
import Badge from "../components/Badge"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/seo"
import { withStyles } from "@material-ui/core/styles"
import { inject, observer } from "mobx-react"
import PropTypes from "prop-types"
import renderHTML from "react-render-html"
import startOfDay from "date-fns/startOfDay"
import addDays from "date-fns/addDays"
// import queryString from 'query-string'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkerAlt,
  faUserFriends,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exp: null,
    }
  }
  componentDidMount = async () => {
    const search = JSON.parse(localStorage.getItem("search"))
    if(localStorage.getItem('searchExp')){
      this.setState({exp:localStorage.getItem('searchExp')})
    }
    if (search && search.houses) {
      await this.props.search.searchHouse(
        new Date(search.checkIn),
        new Date(search.checkOut),
        +search.adults,
        +search.adultsKid,
        search.location,
        "home"
      )
    } else {
      await this.props.search.searchHouse(
        startOfDay(new Date()),
        addDays(startOfDay(new Date()), 1),
        2,
        0,
        "",
        "home"
      )
    }
  }

  render() {
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`;
    const { classes } = this.props;
    const houses = this.props.search.getHousesJS();
    return (
      <Layout page="home" exp={this.state.exp}>
        <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
        <Section>{houses.length ? "บ้านพัก" : "ไม่พบบ้านพัก"}</Section>
        <div className={classes.spaceBox}></div>
        <Grid container spacing={2}>
          {(houses || []).map(val => {
            return (
              <Grid key={val.id} item xs={12} sm={12} md={12}>
                <Link to={`/House?id=${val.id}`}>
                  <div className={classes.boxHouse}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={4}>
                        <img
                          width="100%"
                          src={
                            val.image_is_highlight
                              ? val.image_is_highlight.url
                              : `${process.env.AWS_S3_URL}/assets/img-null.png`
                          }
                          alt="Best Haven Pool Villa Khao Yai"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h3>{val.name}</h3>
                        <div className={classes.locations}>
                          {(val.locations || []).map(element => {
                            return (
                              <Badge key={element.id} color="yellow">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                                {element.name}
                              </Badge>
                            )
                          })}
                        </div>
                        <div className={classes.locations}>
                          <Badge color="white">
                            <FontAwesomeIcon icon={faUserFriends} />{" "}
                            {val.min_person} - {val.max_person}
                          </Badge>
                          <Badge color="blue">
                            <FontAwesomeIcon icon={faUserPlus} />{" "}
                            {val.price_detail.price_per_person} บาท/คืน
                          </Badge>
                        </div>
                        <span className={classes.shotDescription}>
                          {renderHTML(val.short_detail)}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        <div
                          className={
                            val.price_detail.price &&
                            val.price_detail.special_price
                              ? classes.lineLeft
                              : classes.lineLeftNone
                          }
                        >
                          {val.price_detail.price &&
                            val.price_detail.special_price &&
                            (
                              ((val.price_detail.price -
                                val.price_detail.special_price) /
                                val.price_detail.price) *
                              100
                            ).toFixed(0) > 0 && (
                              <div className={classes.save}>
                                ประหยัด{" "}
                                {(
                                  ((val.price_detail.price -
                                    val.price_detail.special_price) /
                                    val.price_detail.price) *
                                  100
                                ).toFixed(0)}
                                % !
                              </div>
                            )}
                          {val.price_detail.price &&
                            val.price_detail.special_price && (
                              <div className={classes.priceBox}>
                                {val.price_detail.price > val.price_detail.special_price &&   <div className={classes.price}>
                                  {val.price_detail.price} บาท
                                </div>}
                                <div className={classes.specialPrice}>
                                  {val.price_detail.special_price} บาท
                                </div>
                                <div className={classes.pricePerDay}>
                                  ราคาเริ่มต้น (ต่อคืน)
                                </div>
                              </div>
                            )}




                          <div className={classes.readMore}>
                            <div className={classes.btnReadmore}>
                              {/* <a href={`/House?id=${val.id}`}> */}
                              <span style={{ color: "#fff" }}>จอง</span>
                              {/* </a> */}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </Layout>
    )
  }
}

const styles = theme => ({
  btnReadmore: {
    textAlign: "center",
    backgroundColor: "#fb726a",
    transition: "0.3s",
    cursor: "pointer",
    padding: "8px 10px !important",
    fontSize: "16px",
    color: "#fff",
    borderRadius: "20px",
    "& a": {
      textDecoration: "none",
    },
  },
  readMore: {
    bottom: 0,
    right: 0,
    position: "absolute",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      left: 0,
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "30%",
    // },
  },
  boxHouse: {
    cursor: "pointer",
    display: "inline-block",
    verticalAlign: "middle",
    WebkitTransform: "translateZ(0)",
    transform: "translateZ(0)",
    boxShadow: "0 0 1px rgba(0, 0, 0, 0)",
    WebkitBackfaceVisibility: "hidden",
    backfaceVisibility: "hidden",
    MozOsxFontSmoothing: "grayscale",
    WebkitTransitionDuration: "0.3s",
    transitionDuration: "0.3s",
    WebkitTransitionProperty: "box-shadow",
    transitionProperty: "box-shadow",
    backgroundColor: "#f7e1d4",
    alignItems: "center",
    border: "none",
    borderRadius: "5px",
    transition: "0.1s",
    padding: "32px 32px!important",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#444444",
    "&:hover": {
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
    },
    "&:focus": {
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
    },
    "&:active": {
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
    },
    "& h3": {
      padding: "0 20px 0 20px",
      fontWeight: "800",
      marginBottom: "15px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
    },
  },
  shotDescription: {
    padding: "0 20px 0 20px",
    // display: 'block',
    height: "83px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  spaceBox: {
    height: "30px",
  },
  save: {
    color: "#00a561",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  priceBox: {
    bottom: "45px",
    right: 0,
    position: "absolute",
  },
  price: {
    color: "#8f8f8f",
    fontSize: "12px",
    lineHeight: 1,
    textDecoration: "line-through",
  },
  pricePerDay: {
    color: "#8f8f8f",
    fontSize: "12px",
  },
  specialPrice: {
    color: "#dc1d2f",
    fontSize: "18px",
    fontWeight: 800,
  },
  lineLeft: {
    borderLeftStyle: "solid",
    borderWidth: "1px",
    borderColor: "gray",
    position: "relative",
    width: "100%",
    height: "100%",
    paddingLeft: "32px",
    // [theme.breakpoints.down("sm")]: {
    //   paddingLeft: '0px',
    // },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      border: "none",
      minHeight: "97px",
    },
  },
  lineLeftNone: {
    borderLeftStyle: "solid",
    borderWidth: "1px",
    borderColor: "gray",
    position: "relative",
    width: "100%",
    height: "100%",
    paddingLeft: "32px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  locations: {
    padding: "0 20px 0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
})

IndexPage.propTypes = {
  classes: PropTypes.object.isRequired,
}
export const page = inject("search", "house")(observer(IndexPage))
export default withStyles(styles, { withTheme: true })(page)
